<template>
  <Icon :icon="'mdi:' + iconName" :width="width" :height="height" color='black' :class="props.color + '_svg'" />
</template>

<script setup>
import { computed } from 'vue';
import { Icon } from '@iconify/vue';

const props = defineProps(['width', 'height', 'color']);

const iconName = computed(() => {
  if (slots.default && slots.default().length > 0) {
    return slots.default()[0].children.trim();
  }
  return "";
});

const slots = defineSlots();
</script>

<style>
.white_svg {
  filter: invert(100%) sepia(98%) saturate(8%) hue-rotate(155deg) brightness(102%) contrast(106%);
}

.green_svg {
  filter: invert(32%) sepia(100%) saturate(853%) hue-rotate(178deg) brightness(93%) contrast(88%);
}

.primary_svg {

  filter: invert(51%) sepia(39%) saturate(530%) hue-rotate(104deg) brightness(94%) contrast(88%);
}

[data-theme="blue"] .primary_svg {
  filter: invert(34%) sepia(79%) saturate(1084%) hue-rotate(179deg) brightness(90%) contrast(87%) !important;
}

.red_svg {
  filter: invert(34%) sepia(76%) saturate(1102%) hue-rotate(326deg) brightness(102%) contrast(81%);
}

.grey_svg {
  filter: invert(76%) sepia(10%) saturate(156%) hue-rotate(165deg) brightness(89%) contrast(91%);
}

.blue_svg {
  filter: invert(71%) sepia(18%) saturate(999%) hue-rotate(164deg) brightness(95%) contrast(88%);
}

.dark_svg {
  filter: invert(15%) sepia(31%) saturate(753%) hue-rotate(178deg) brightness(94%) contrast(85%);
}
</style>